import * as sha256 from 'sha256';

const uvarint64ToBuf = (uint) => {
    const result = [];

    while (uint >= 0x80) {
        result.push(Number((BigInt(uint) & BigInt(0xff)) | BigInt(0x80)));
        uint = Number(BigInt(uint) >> BigInt(7));
    }

    result.push(uint | 0);

    return new Buffer(result);
};

export function signTransaction(
    privateKey,
    // seedHex,
    transactionHex,
    isDerivedKey
) {
    // const privateKey = this.cryptoService.seedHexToPrivateKey(seedHex);

    const transactionBytes = new Buffer(transactionHex, 'hex');
    const transactionHash = new Buffer(sha256.x2(transactionBytes), 'hex');
    const signature = privateKey.sign(transactionHash, {canonical: true});
    const signatureBytes = new Buffer(signature.toDER());
    const signatureLength = uvarint64ToBuf(signatureBytes.length);

    // If transaction is signed with a derived key, use DeSo-DER recoverable signature encoding.
    if (isDerivedKey) {
        signatureBytes[0] += 1 + (signature.recoveryParam);
    }

    const signedTransactionBytes = Buffer.concat([
        // This slice is bad. We need to remove the existing signature length field prior to appending the new one.
        // Once we have frontend transaction construction we won't need to do this.
        transactionBytes.slice(0, -1),
        signatureLength,
        signatureBytes,
    ]);

    return signedTransactionBytes.toString('hex');
}

function deSoApiCall(cmd, request) {
    return fetch("https://node.deso.org/api/v0/" + cmd, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request)
    }).then(response => response.json())
}

export function sendDeSo(privateKey, senderPubkey, recipientPubkeyOrUsername, amountNanos) {
    deSoApiCall("send-deso", {
        SenderPublicKeyBase58Check: senderPubkey,
        RecipientPublicKeyOrUsername: recipientPubkeyOrUsername,
        AmountNanos: amountNanos,
        MinFeeRateNanosPerKB: 1000,
        TransactionFees: []
    }).then(json => {
        console.log(json);
        let signedTxn = signTransaction(privateKey, json.TransactionHex);
        console.log(signedTxn)
        deSoApiCall("submit-transaction", {
            TransactionHex: signedTxn
        }).then(r => console.log(r))
    })
}

// Does not work because of CORS...
// export function submitTxn(transactionHex) {
//     return deSoApiCall("submit-transaction", {
//         TransactionHex: transactionHex
//     })
// }

export async function isUsernameAvailable(username) {
    try {
        await deSoApiCall("get-single-profile", {
            "PublicKeyBase58Check": "",
            "Username": username,
            "NoErrorOnMissing": true
        });
        return false
    } catch (error) {
        return true
    }
}

export function createFollowTxn(followerPubkey, followedPubkey, isUnfollow=false) {
   return deSoApiCall("create-follow-txn-stateless", {
        FollowerPublicKeyBase58Check: followerPubkey,
        FollowedPublicKeyBase58Check: followedPubkey,
        IsUnfollow: isUnfollow,
        MinFeeRateNanosPerKB: 1000,
        TransactionFees: []
    })
}
