// import {pbkdf2Sync} from 'pbkdf2'
import {createHash, pbkdf2Sync} from 'crypto'


const getPasswordKey = (password) =>
    window.crypto.subtle.importKey("raw", new TextEncoder().encode(password), "PBKDF2", false, [
        "deriveKey",
    ]);

const deriveKey = (passwordKey, salt, keyUsage) =>
    window.crypto.subtle.deriveKey(
        {
            name: "PBKDF2",
            salt: salt,
            iterations: 310_000,
            hash: "SHA-256",
        },
        passwordKey,
        {name: "AES-GCM", length: 256},
        false,
        keyUsage
    );

export async function encrypt(plaintext, password) {
    try {
        const salt = window.crypto.getRandomValues(new Uint8Array(16));
        const iv = window.crypto.getRandomValues(new Uint8Array(12));
        const passwordKey = await getPasswordKey(password);
        const aesKey = await deriveKey(passwordKey, salt, ["encrypt"]);
        const encryptedContent = await window.crypto.subtle.encrypt(
            {
                name: "AES-GCM",
                iv: iv,
            },
            aesKey,
            new TextEncoder().encode(plaintext)
        );

        const encryptedContentArr = new Uint8Array(encryptedContent);
        let buff = new Uint8Array(
            1 + salt.byteLength + iv.byteLength + encryptedContentArr.byteLength
        );
        buff.set([1], 0) // version
        buff.set(salt, 1);
        buff.set(iv, 1 + salt.byteLength);
        buff.set(encryptedContentArr, 1 + salt.byteLength + iv.byteLength);
        return btoa(String.fromCharCode.apply(null, buff));
    } catch (e) {
        console.log(`Error - ${e}`);
        return "";
    }
}

export async function decrypt(ciphertext, password) {
    try {
        const encryptedDataBuff = Uint8Array.from(atob(ciphertext), (c) => c.charCodeAt(null));
        const version = encryptedDataBuff.slice(0, 1);
        const salt = encryptedDataBuff.slice(1, 1 + 16);
        const iv = encryptedDataBuff.slice(1 + 16, 1 + 16 + 12);
        const data = encryptedDataBuff.slice(1 + 16 + 12);
        const passwordKey = await getPasswordKey(password);
        const aesKey = await deriveKey(passwordKey, salt, ["decrypt"]);
        const decryptedContent = await window.crypto.subtle.decrypt(
            {
                name: "AES-GCM",
                iv: iv,
            },
            aesKey,
            data
        );
        return new TextDecoder().decode(decryptedContent);
    } catch (e) {
        console.log(`Error - ${e}`);
        return null;
    }
}

export function createAccessKey(password, pubkey) {
    let hash = pbkdf2Sync(createHash('sha512').update(password).digest('hex'), pubkey, 1024, 32, 'sha256')
    return btoa(String.fromCharCode.apply(null, new Uint8Array(hash)));
}
