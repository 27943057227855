import {mnemonicToPrivateKey, newRandomMnemonic, privateKeyToDeSoPublicKey} from './seeds'
import {createFollowTxn, isUsernameAvailable} from "./deso";
import {debounce} from 'lodash';
import {createAccessKey, decrypt, encrypt} from "./encryption";
import {getEncSeedPhrase, setUsername, signAndSubmitTxn, signUp} from "./desosignup";

// let testData = JSON.stringify({
//     "test": 42
// })

// encryptData(testData, "secret").then(ciphertext => {
//     console.log(ciphertext)
//
//     decryptData(ciphertext,"secret").then((plaintext)=>console.log(plaintext))
// })

// let mnemonic = newRandomMnemonic();
// console.log(mnemonic)
//
// encryptData(mnemonic, "secret").then(ciphertext => {
//     console.log(ciphertext)
//     decryptData(ciphertext, "secret").then((plaintext) => console.log(plaintext))
// })
//
// let privateKey = mnemonicToPrivateKey(mnemonic);
// console.log(privateKey)
//
// console.log("Signed", signTransaction(privateKey, "0x42", false))


// mnemonic="impulse night habit fee famous absorb loud six pluck miss day theme" // DeSoTradeBot
// privateKey = mnemonicToPrivateKey(mnemonic);
// sendDeSo(privateKey,privateKeyToDeSoPublicKey(privateKey),"gist",3793)

let minPasswordLength = 8
const userNameField = document.querySelector("#username");
const passwordField = document.querySelector("#password");
const signUpForm = document.querySelector("#signUpForm");
const submitBtn = document.querySelector("#submitBtn");
const confirmPasswordField = document.querySelector("#confirm-password");
const confirmPasswordErrorIcon = document.querySelector("#confirm-password-error-icon");

// const userNameNeedsPrefix = document.body.dataset.isBeta
const userNameNeedsPrefix = false

let userNameAvailable = false
let passwordsMatch = false;

if (passwordField) minPasswordLength = passwordField.minLength

function updateSubmitBtn() {
    // console.log(userNameAvailable)
    // console.log(passwordsMatch)
    // console.log(minPasswordLength)
    // console.log(passwordField.value.length)
    submitBtn.disabled = !(passwordField.value.length >= minPasswordLength && userNameAvailable && passwordsMatch)
    submitBtn.style.opacity = submitBtn.disabled ? "50%" : "100%"
}

if (userNameField) {
    if (userNameNeedsPrefix && userNameField.value == "") userNameField.value = "_"
    userNameField.oninput = function () {
        const userNameAvailableMsg = document.querySelector("#username-error");
        const lookup = debounce(() => {
                isUsernameAvailable(userNameField.value).then((available) => {
                    // userNameAvailableMsg.innerText = available;
                    if (available) {
                        userNameAvailableMsg.classList.remove("text-red-600");
                        document.querySelector("#username-error-icon").classList.add("hidden")
                        userNameField.classList.add("border-gray-300")
                        userNameField.classList.remove("border-red-300")
                        userNameField.classList.remove("focus:border-red-500")
                        userNameField.classList.add("focus:border-indigo-500")
                        // submitBtn.style.opacity = "100%"
                    } else {
                        userNameAvailableMsg.classList.add("text-red-600")
                        document.querySelector("#username-error-icon").classList.remove("hidden")
                        userNameField.classList.remove("border-gray-300")
                        userNameField.classList.add("border-red-300")
                        userNameField.classList.add("focus:border-red-500")
                        userNameField.classList.remove("focus:border-indigo-500")
                        // submitBtn.style.opacity = "50%"
                    }
                    // submitBtn.disabled = !available
                    userNameAvailable = available
                    updateSubmitBtn()
                    userNameAvailableMsg.innerText = available ? "✔ available" : "❌ taken";
                });
            }, 500
        )

        // BETA: name must start with _
        if (userNameNeedsPrefix && !userNameField.value.startsWith("_")) {
            userNameField.value = "_" + userNameField.value
        }

        userNameAvailableMsg.innerText = "⏳"
        lookup()
    }
}

async function createNewAccount(username, password) {
    let mnemonic = newRandomMnemonic();
    let privateKey = mnemonicToPrivateKey(mnemonic);
    let pubkey = privateKeyToDeSoPublicKey(privateKey)


    let encSeedPhrase = await encrypt(mnemonic, password);

    signUp(pubkey, encSeedPhrase, createAccessKey(password, pubkey)).then(async (response) => {
        // console.log(password)
        // console.log(response.EncSeedPhrase);
        // console.log(await decrypt(response.EncSeedPhrase, password));

        let setUsernameTxn = await setUsername(pubkey, username)
        // console.log(txn)

        // let signed = signTransaction(privateKey, txn.TransactionHex, false)
        // console.log(signed)
        // console.log(await submitTxn(signed))
        // await submitTxn(signed)

        await signAndSubmitTxn(privateKey, setUsernameTxn.TransactionHex, false)

        // let followTxn = await createFollowTxn(pubkey, "BC1YLjWuKM94ondCb1KW7UXx7qGRFNxidmMnaJ4MHCChfghtwtBZ1HQ")
        // if (followTxn && followTxn.TransactionHex) {
        //     await signAndSubmitTxn(privateKey, followTxn.TransactionHex, false)
        // }
        try {
            for (const follow of response.FollowPubkeys) {
                let followTxn = await createFollowTxn(pubkey, follow)
                if (followTxn && followTxn.TransactionHex) {
                    await signAndSubmitTxn(privateKey, followTxn.TransactionHex, false)
                }
            }
        } finally {
            window.location.assign("/acc/" + pubkey);
        }

    })
}

if (signUpForm) {

    passwordField.oninput = confirmPasswordField.oninput = () => {
        console.log("Password input")
        passwordsMatch = passwordField.value === confirmPasswordField.value;
        if (passwordsMatch) {
            confirmPasswordErrorIcon.classList.add("hidden")
        } else {
            confirmPasswordErrorIcon.classList.remove("hidden")
        }
        updateSubmitBtn()
    }

    signUpForm.onsubmit = (event) => {
        event.preventDefault()
        submitBtn.disabled = true
        document.querySelector("#throbber").classList.remove("hidden")

        window.setTimeout(() => {
            document.querySelector("#pleaseWait").classList.remove("hidden")
        }, 5000)

        addEventListener("beforeunload", () => {
            event.preventDefault();
            return event.returnValue = "Warning: leaving the page may lead to loosing your username! Please cancel and wait for the operation to finish.";
        }, {capture: true});


        createNewAccount(userNameField.value, passwordField.value)

        // document.querySelector("#step-1").classList.add("hidden")
        // document.querySelector("#step-2").classList.remove("hidden")
    }
}

let seedPhraseForm = document.querySelector("form#seedPhraseForm");
// if (seedPhraseForm) seedPhraseForm.onsubmit = async (event) => {
//     event.preventDefault()
//     let pwField = document.querySelector("#seed-phrase-password");
//     let pw = pwField.value
//     let ciphertext = seedPhraseForm.dataset.encSeedPhrase;
//     let seedPhrase = await decrypt(ciphertext, pw);
//     // console.log(ciphertext, pw, seedPhrase)
//     if (seedPhrase) {
//         seedPhraseForm.classList.add("hidden");
//         let seedPhraseField = document.querySelector("#seedPhrase");
//         seedPhraseField.value = seedPhrase
//         seedPhraseField.classList.remove("hidden")
//     } else {
//         pwField.value = ""
//         alert("Wrong password, please try again!")
//     }
// }
if (seedPhraseForm) seedPhraseForm.onsubmit = async (event) => {
    event.preventDefault()
    let pwField = document.querySelector("#seed-phrase-password");
    let pw = pwField.value
    let res = await getEncSeedPhrase(seedPhraseForm.dataset.pubkey, pw)
    console.log(res)
    let ciphertext = res.EncSeedPhrase
    let seedPhrase = await decrypt(ciphertext, pw);
    // console.log(ciphertext, pw, seedPhrase)
    if (seedPhrase) {
        seedPhraseForm.classList.add("hidden");
        let seedPhraseField = document.querySelector("#seedPhrase");
        seedPhraseField.value = seedPhrase
        document.querySelector("#seedPhraseDownloadBtn").href = "data:text/plain;charset=utf-8," + encodeURIComponent(seedPhrase)
        document.querySelector("#seedPhraseRevealed").classList.remove("hidden")
    } else {
        pwField.value = ""
        alert("Wrong password, please try again!")
    }
}

const understoodDiv = document.querySelector("#understood")
if (understoodDiv) {
    const understoodCheckbox = understoodDiv.querySelector("input")
    understoodCheckbox.onchange = () => {
        understoodDiv.classList.add("hidden")
        document.querySelector("#loginPrompt").classList.remove("hidden")
    }
}

// (async function () {
//     let pw = "secret"
//     let text = "test"
//     let ciphertext = await encrypt(text, pw)
//     console.log(await decrypt(ciphertext, pw))
// }())
// (async function () {
//     let pubkey = "BC1YLftuSgfWouwRemdFiqmQuq4nCrfyrRMsL7KhAk1TGdMH4SE3YJT"
//     let pw = "123123123"
//     console.log(createAccessKey(pw, pubkey))
// }());
