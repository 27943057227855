import {createAccessKey} from "./encryption";
import {signTransaction} from "./deso";

function apiCall(cmd, request) {
    return fetch("/api/" + cmd, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request)
    }).then(response => response.json())
}

export function signUp(pubkey, encSeedPhrase, accessKey) {
    return apiCall("sign-up", {
        Pubkey: pubkey,
        EncSeedPhrase: encSeedPhrase,
        AccessKey: accessKey
    })
}

export function setUsername(pubkey, username) {
    return apiCall("set-username", {
        Pubkey: pubkey,
        Username: username
    })
}

export function signAndSubmitTxn(privateKey, transactionHex, isDerivedKey) {
    return submitTxn(signTransaction(privateKey, transactionHex, isDerivedKey))
}

export function submitTxn(transactionHex) {
    return apiCall("submit-transaction", {
        TransactionHex: transactionHex
    })
}

export async function getEncSeedPhrase(pubkey, password) {
    return apiCall("get-enc-seed-phrase", {
        Pubkey: pubkey,
        AccessKey: createAccessKey(password, pubkey)
    })
}
